export function getUserLevelSymbol(totalPaid: number): string {
    if (totalPaid > 20000) {
      return '⭐⭐⭐';
    } else if (totalPaid > 10000) {
      return '⭐⭐';
    } else if (totalPaid > 5000) {
      return '⭐';
    } else {
      return ''; // No symbol for users below $5000
    }
  }