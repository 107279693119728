import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  public invoiceUrl: string = environment.serverUrl + '/invoice'
  constructor(private http: HttpClient) { }
  remindeInvoice(invoice_id: number) {
    return this.http.get(this.invoiceUrl + '/send-remind/' + invoice_id)
  }
  markAsPaidInvoice(invoice_id: number) {
    return this.http.get(this.invoiceUrl + '/mark-as-paid/' + invoice_id)
  }
  cencelInvoice(invoice_id: number) {
    return this.http.get(this.invoiceUrl + '/cencel-invoice/' + invoice_id)
  }
  createInvoice(data: any) {

    return this.http.post(this.invoiceUrl, data)
  }

   // To get invoice total amount for star rating
   getInvoiceTotalForStarRating(idArray: any) {
    return this.http.post(this.invoiceUrl + `/invoces-total`,idArray)
  }
}
