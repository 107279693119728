import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as JsBarcode from 'jsbarcode';
import { PdfRecieptService } from './pdf-reciept.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { getUserLevelSymbol } from '../../spendes/userlevel.util';
import { InvoiceService } from 'src/app/core/services/invoice.service';
// import * as JSPM from 'jsprintmanager'
// import { PrintDriver } from "ng-thermal-print/lib/drivers/PrintDriver";
// import { PrintService, UsbDriver, WebPrintDriver } from "ng-thermal-print";

@Component({
  selector: 'app-pdf-receipt',
  templateUrl: './pdf-receipt.component.html',
  styleUrls: ['./pdf-receipt.component.scss']
})
export class PdfReceiptComponent implements OnInit {
  livestream_name!: string;
  product_name!: string;
  price!: number;
  host!:string;
  platform!:string;
  quantity!: string;
  order_id!: string;
  time: any;
  printted = false;
  @ViewChild('print', { static: false }) printRef!: ElementRef<any>;
  clientName: any;
  JSPM: any;
  cpj: any;
  // usbPrintDriver!: UsbDriver;
  constructor(private receiptService: PdfRecieptService, private invoiceService: InvoiceService) {
    let inProcess=false
  
    this.receiptService.orderObserber.subscribe(async(res: any) => {
      
      if (res) {
        const resultArray = Array.isArray(res) ? res : [res];
        const startNamesData = await this.getUserDisplayName(resultArray);
        console.log('startNamesData1',startNamesData);
      
        // // Type assertion to ensure it's treated as an array
        let final_name = '';
        if (Array.isArray(startNamesData) && startNamesData.length > 0) {
          console.log(startNamesData,'startNamesData');
          
          // final_name = startNamesData[res.client_name]; // Get the last element
          const validNames = startNamesData.filter(name => typeof name === 'string');
  
          // Find the matching name with a star
          final_name = validNames.find(name => name.includes(res.client_name)) || '';
        } 
      
        // Check if the properties have already been set to avoid redundant processing
        if (
          this.livestream_name === res.livestream_name &&
          this.product_name === res.product &&
          this.price === res.price &&
          this.quantity === res.quantity &&
          this.clientName === res.client_name
        ) {
          return; // No change, exit early
        }
        
      
        // Update values when any of the conditions above are met
        inProcess = true;
        this.livestream_name = res.livestream_name || '-';
        this.product_name = res.product || '-';
        this.price = res.price || 0;
        this.host = res.host || '';
        this.platform = res.platform || '';
        this.quantity = res.quantity || 0;
        this.order_id = res.orderId || '-';
        this.clientName = res.client_name

        // Ensure clientName is updated if final_name is defined
        console.log(final_name,'final_name');
        if (final_name) {
          this.clientName = final_name;
        } else {
          this.clientName = res.client_name || ''; // Default fallback
        }
      
        // Call the methods
        this.getDate();
        this.breackLineForTime();
      }
    })
  }
 
  startNames:any = [];
  async getUserDisplayName(allRecord: any) {
    console.log('allRecord',allRecord);
   return new Promise((resolve,reject) => {
     let arrayId = allRecord.map((data:any) => data.client);
     const data = {"client_id": arrayId}
     this.invoiceService.getInvoiceTotalForStarRating(data)
     .subscribe((res: any) => {
        console.log('res',res);
        res?.data?.clients.forEach((element:any) => {
          const symbol = getUserLevelSymbol(element?.total || 0);
          this.startNames[element?.client_id] = `${symbol} ${element?.user_name || ''}`.trim();  
          resolve(this.startNames)
        });
      });
    });
  }
  
  ngOnInit(): void {
  }
  
  breackLineForTime(){
    try {
      let c :string[]= (this.time as string).split(' ')
    
      let k = c.splice(c.length-2,0,'<br>')
      return c.join(" ")
    } catch (error) {
      return '';
    }
    
  }

  generateBarCode(text: string): String {
    var canvas = document.createElement("canvas");

    JsBarcode(canvas, text, { format: "CODE128" });
    return canvas.toDataURL("image/png");
  }
  
  getDate() {
    var options: any = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
    let d = new Date()
    this.time = d.toLocaleDateString('en-US', options) + " " + d.toLocaleTimeString('en-US');
  }
  
  printByNgx() {

  }
  
  printReciept() {
    this.printReciept2()
    return;

    var doc = new jsPDF();
    let elementHTML: HTMLElement | any = window.document.querySelector("#htmlData");
    let _this = this
    doc.html(elementHTML, {
      callback: function (doc2: any) {

        doc2.autoPrint()
        let blob = doc2.output('blob')
        let file = new Blob([blob], { type: 'application/pdf' });
        let fileURL = URL.createObjectURL(file);
        let win: any = window.open('');
        win.document.write('<iframe src="' + fileURL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')
        win.blur();
        win.opener.focus();
        // setTimeout(() => {
        //   win.close()
        // }, 5000)
      },
      x: 0,
      y: 5,

      width: 140,
      windowWidth: 540

    });
  }
  
  prevPrint:string='';
  printReciept2() {
    let DATA: HTMLElement | any = window.document.querySelector("#htmlData");
    if(this.prevPrint == DATA.innerText){
      return;
    }

    // return window.print()
    
    this.prevPrint = DATA.innerText
    let _this=this;
    html2canvas(DATA).then(async(canvas: any) => {
      
      // let fileWidth = 208;
      // let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      // let PDF = new jsPDF('portrait','mm',[160,78]);
      // let position = 0;
      // var width = PDF.internal.pageSize.getWidth();
      // var height = PDF.internal.pageSize.getHeight();
      // PDF.addImage(FILEURI, 'PNG', 0, 0,width-5,height-5);
      const pdf = new jsPDF('portrait','mm',[100,78]);
    const imgProps = pdf.getImageProperties(FILEURI);
    const margin = 0.1;
 
    const pdfWidth = pdf.internal.pageSize.width * (1 - margin);
    const pdfHeight = pdf.internal.pageSize.height * (1 - margin);
 
    const x = pdf.internal.pageSize.width * (margin / 2);
    const y = pdf.internal.pageSize.height * (margin / 2);
 
    const widthRatio = pdfWidth / imgProps.width;
    const heightRatio = pdfHeight / imgProps.height;
    const ratio = Math.min(widthRatio, heightRatio);
    
    const w = imgProps.width * ratio;
    const h = imgProps.height * ratio;
 
    pdf.addImage(FILEURI, "JPEG", x, y, w, h);
      pdf.autoPrint()
      let url = (pdf.output('blob'))
      // let win =window
      const mywindow :Window|any= window.open(window.URL.createObjectURL(url),"PRINT")
      mywindow?.blur();
      // win.focus()
      // await _this.wait(5500)
        // mywindow?.close()
        // window.focus();
       
    });

  }
  
  wait(val: number) {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(true), 50)
    })
  }

  getTotal(){
    return this.price * Number(this.quantity);
  }

}
